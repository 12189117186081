<template>
  <div class="home-content">
    <el-row>
      <el-col :lg="{span:12, offset:6}">
        <YMMComponent title="SHOP BY VEHICLE"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import YMMComponent from '@/components/ymm/YMMComponent'

export default {
  name: 'Home',
  components: {
    YMMComponent
  }
}
</script>
<style lang="scss">
.home-content{
  position:relative;
  height: 100%;
  .el-row{
    height:inherit
  }
}
</style>
